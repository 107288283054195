import React, { useState } from "react";
import {
  Accordions,
  Accordionsitems,
  AccordionsContent,
  AccordionsContentinner,
  AccordionsTitle,
} from "../Accordion";

const FaqAccordionStatic = ({ faqs }) => {
  const [activeFaq, setActiveFaq] = useState(0);
  const handleAccodion = (key) => {
    if (activeFaq === key) {
      return setActiveFaq(null);
    }
    setActiveFaq(key);
  };
  return (
    <Accordions className="accordions">
      {faqs.map((faq, index) => (
        <Accordionsitems
          className={`accordions-items ${activeFaq === index ? "active" : ""}`}
          key={index}
        >
          <AccordionsTitle
            className="accordions-title"
            onClick={() => handleAccodion(index)}
          >
            <i></i>
            <strong>{faq.node.question}</strong>
            <span></span>
          </AccordionsTitle>
          <AccordionsContent className="accordions-content">
            <AccordionsContentinner
              dangerouslySetInnerHTML={{
                __html: faq.node.answer.childMarkdownRemark.html,
              }}
            />
          </AccordionsContent>
        </Accordionsitems>
      ))}
    </Accordions>
  );
};

export default FaqAccordionStatic;
