import styled from 'styled-components'

export const Accordions = styled.div` 
  counter-reset: section;
`
export const Accordionsitems = styled.div`
  position:relative;
  overflow: hidden;
  border: 1px solid #eef0fd;
  border-radius:4px;
  @media (min-width: 768px) {    
    border-radius:6px;
  }
  @media (min-width: 992px) {
    border-radius:10px;
  }
  &.active{
    /* border-color:#E9242A; */
    opacity:1;
    .accordions-title{
      /* background-color:#E9242A;
      color:#fff; */
    }
  }
  + .accordions-items{
    margin-top:20px;
  }
  &.active{
    span{
      transform:rotate(135deg);
      &:before,
      &:after{
        background:#000;
      }
    }
    .accordions-content{
      max-height:1000px;
      overflow:inherit;
      transition: max-height 0.2s ease-out;
    }
    &:before{
      opacity:1;
    }
  }
`
export const AccordionsContent = styled.div`
  max-height:0;
  overflow:hidden;
  transition: max-height .35s ease;
  position: relative;
`
export const AccordionsContentinner = styled.div`
  background-color:#fff;
  padding:15px;
  @media (min-width: 768px) {
    padding:20px;
  }
  @media (min-width: 992px) {
    padding:30px;
  }
  font-size: 16px;
  line-height: 28px;
  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 30px;
  }
  > p{
    font-size: 16px;
    line-height: 28px;
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
`
export const AccordionsTitle = styled.div`  
  background-color:${props=>props.bgColor};
  cursor: pointer;
  display:flex;
  align-items:center;
  justify-content:space-between;
  position:relative;
  z-index:1;
  color: #373636;
  font-weight: 600;
  padding:6px;
  @media (min-width: 768px) {    
    padding:8px;
  }
  font-size: 16px;
  line-height: 28px;
  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 30px;
    padding:10px;
  }
  i{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    min-width:40px;
    min-height:40px;
    background-color:#fff;
    color:#373636;
    font-style: normal;
    font-weight:600;
    font-size: 16px;
    line-height: 24px;
    border-radius:4px;
    @media (min-width: 768px) {    
      border-radius:6px;
    }
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      border-radius:10px;
    }
    &::before {
      counter-increment: section;
      content: "Q" counter(section) " ";
    }
  }
  strong{
    font-weight:600;
    margin:0 10px;
    @media (min-width: 768px) {         
      margin:0 15px;
    }
    @media (min-width: 992px) {
      margin:0 20px;
    }
  }
  span{
    width:30px; 
    min-width: 30px;
    height:30px; 
    position:relative;
    display:inline-block;
    transition: all .35s ease;
    margin-left:30px;
    margin-left: auto;
    &:before,
    &:after{
      display:block;
      content:'';
      position:absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      background:#02044A;
    }
    &:before{
      width:2px;
      height:50%;
    }
    &:after{
      width:50%;
      height:2px;
    }
  }
`

AccordionsTitle.defaultProps = {
	bgColor: "#EEF0FD"
}