import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  ProductCard,
  ProductBody,
  ProductFooter,
  ProductSku,
  ProductFigure,
  ProductName,
  ProductPrice,
} from "../ProductStyle";

const TopSellingProduct = ({ data }) => {
  return (
    <ProductCard className="card card-grid">
      <ProductBody className="card-body">
        <ProductFigure className="card-figure">
          <ProductSku className="product-sku">SKU : {data.skuNumber}</ProductSku>
          <Link to={data.url}>
            <GatsbyImage
              image={data.image.gatsbyImageData}
              alt={data.title}
            />
          </Link>
          {/* <ProductDtl className="product-dtl"><Link to="/48x30x12-10-gambrel-barn"><DefaultButton icon={<DetailIcon />} text="View Details" size="xs"/></Link></ProductDtl> */}
        </ProductFigure>
      </ProductBody>
      <ProductFooter className="card-footer">
        <ProductName className="product-name"><Link to={data.url}>{data.title}</Link></ProductName>
        <ProductPrice className="product-price"><small>Starting Price:</small> ${data.price.toLocaleString("en-US")}</ProductPrice>
      </ProductFooter>
    </ProductCard>
  );
};
export default TopSellingProduct;
